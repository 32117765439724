import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { TheLayout } from './containers';
import Loader from './reusable/Loader';
import './scss/style.scss';

const Home = React.lazy(() => import('./views/Home/Home'));
const App = () => {



  return (
    <BrowserRouter>
      <TheLayout>
        {/*<h1 style={{ textAlign: 'center', color: 'red' }}>Down for Maintenance, be back soon!</h1>*/}
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route path="/" name="Home"
              render={(props) => (
                //<h1 style={{ textAlign: 'center', color: 'red' }}>Down for Maintenance, be back soon!</h1>
                <Home {...props} />
              )}
            />
            <Route path="/catalogue" name="Categories" render={(props) => (
              //<h1 style={{textAlign:'center', color:'red'}}>Down for Maintenance, be back soon!</h1>
              <Home {...props} />
            )} />
          </Switch>
        </React.Suspense>
      </TheLayout>
    </BrowserRouter>
  );
}

export default App;
